
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
@use './themes.scss' as themes;
// Plus imports for other components in your app.


// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

$public-menu-primary: (
    50 : #e2e2e2,
    100 : #bcbcbc,
    200 : #909090,
    300 : #646464,
    400 : #424242,
    500 : #212121,
    600 : #1d1d1d,
    700 : #181818,
    800 : #141414,
    900 : #0b0b0b,
    A100 : #d45500,
    A200 : #cf4e00,
    A400 : #c94400,
    A700 : #c33b00,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #ffffff,
        A200 : #ffffff,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

$public-menu-accent: (
    50 : #fff6ef,
    100 : #f2ccb3,
    200 : #eaaa80,
    300 : #e1884d,
    400 : #da6f26,
    500 : #d45500,
    600 : #cf4e00,
    700 : #c94400,
    800 : #c33b00,
    900 : #b92a00,
    A100 : #ffe7e2,
    A200 : #ffbcaf,
    A400 : #ff917c,
    A700 : #ff7b63,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$public-menu-primary: mat.m2-define-palette($public-menu-primary, 500, 50, 900, 500);
$public-menu-accent: mat.m2-define-palette($public-menu-accent, 500, 50, 900);
$public-menu-accent--red: mat.m2-define-palette(mat.$m2-red-palette, 500, 50, 900);
$public-menu-accent--pink: mat.m2-define-palette(mat.$m2-pink-palette, 500, 50, 900);
$public-menu-accent--purple: mat.m2-define-palette(mat.$m2-purple-palette, 500, 50, 900);
$public-menu-accent--deep-purple: mat.m2-define-palette(mat.$m2-deep-purple-palette, 500, 50, 900);
$public-menu-accent--indigo: mat.m2-define-palette(mat.$m2-indigo-palette, 500, 50, 900);
$public-menu-accent--blue: mat.m2-define-palette(mat.$m2-blue-palette, 500, 50, 900);
$public-menu-accent--light-blue: mat.m2-define-palette(mat.$m2-light-blue-palette, 500, 50, 900);
$public-menu-accent--cyan: mat.m2-define-palette(mat.$m2-cyan-palette, 500, 50, 900);
$public-menu-accent--teal: mat.m2-define-palette(mat.$m2-teal-palette, 500, 50, 900);
$public-menu-accent--green: mat.m2-define-palette(mat.$m2-green-palette, 500, 50, 900);
$public-menu-accent--light-green: mat.m2-define-palette(mat.$m2-light-green-palette, 500, 50, 900);
$public-menu-accent--lime: mat.m2-define-palette(mat.$m2-lime-palette, 500, 50, 900);
$public-menu-accent--yellow: mat.m2-define-palette(mat.$m2-yellow-palette, 500, 50, 900);
$public-menu-accent--amber: mat.m2-define-palette(mat.$m2-amber-palette, 500, 50, 900);
$public-menu-accent--orange: mat.m2-define-palette(mat.$m2-orange-palette, 500, 50, 900);
$public-menu-accent--deep-orange: mat.m2-define-palette(mat.$m2-deep-orange-palette, 500, 50, 900);
$public-menu-accent--brown: mat.m2-define-palette(mat.$m2-brown-palette, 500, 50, 900);
$public-menu-accent--grey: mat.m2-define-palette(mat.$m2-grey-palette, 500, 50, 900);
$public-menu-accent--blue-grey: mat.m2-define-palette(mat.$m2-blue-grey-palette, 500, 50, 900);

// The warn palette is optional (defaults to red).
$public-menu-warn: mat.m2-define-palette(mat.$m2-red-palette, 500, 50, 900);

$basic-typography-level: mat.m2-define-typography-level(
  $font-family: 'Roboto, "Helvetica Neue", sans-serif',
  $font-size: 14px,
  $font-weight: 400,
  $letter-spacing: normal,
  $line-height: 1.25
);

$button-typography-level: mat.m2-define-typography-level(
  $font-family: 'Roboto, "Helvetica Neue", sans-serif',
  $font-size: 14px,
  $font-weight: 500,
  $letter-spacing: normal,
  $line-height: 1.5
);

$headline1-typography-level: mat.m2-define-typography-level(
  $font-family: 'Roboto, "Helvetica Neue", sans-serif',
  $font-size: 24px,
  $font-weight: 500,
  $letter-spacing: normal,
  $line-height: 1.5
);

$headline2-typography-level: mat.m2-define-typography-level(
  $font-family: 'Roboto, "Helvetica Neue", sans-serif',
  $font-size: 20px,
  $font-weight: 500,
  $letter-spacing: normal,
  $line-height: 1.5
);

$public-menu-typography-config: mat.m2-define-typography-config(
  $font-family: 'Roboto, "Helvetica Neue", sans-serif',
  $headline-1: $headline1-typography-level,
  $headline-2: $headline2-typography-level,
  $headline-5: $headline1-typography-level,
  $headline-6: $headline2-typography-level,
  $body-1: $basic-typography-level,
);

$button-typography-config: mat.m2-define-typography-config(
  $font-family: 'Roboto, "Helvetica Neue", sans-serif',
  $body-1: $button-typography-level,
  $body-2: $button-typography-level,
  $button: $button-typography-level,
);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".

// Light themes
$public-menu-light-theme: mat.m2-define-light-theme((
  color: (
    primary: $public-menu-primary,
    accent: $public-menu-accent,
    warn: $public-menu-warn,
  ),
  typography: $public-menu-typography-config,
  density: 0
));

$button-theme: mat.m2-define-light-theme((
  color: (
    primary: $public-menu-primary,
    accent: $public-menu-accent,
  ),
  typography: $button-typography-config,
));

$public-menu-light-theme--red: mat.m2-define-light-theme((
  color: (
    primary: $public-menu-primary,
    accent: $public-menu-accent--red,
    warn: $public-menu-warn,
  ),
  typography: $public-menu-typography-config,
  density: 0
));

$public-menu-light-theme--pink: mat.m2-define-light-theme((
  color: (
    primary: $public-menu-primary,
    accent: $public-menu-accent--pink,
    warn: $public-menu-warn,
  ),
  typography: $public-menu-typography-config,
  density: 0
));

$public-menu-light-theme--purple: mat.m2-define-light-theme((
  color: (
    primary: $public-menu-primary,
    accent: $public-menu-accent--purple,
    warn: $public-menu-warn,
  ),
  typography: $public-menu-typography-config,
  density: 0
));

$public-menu-light-theme--deep-purple: mat.m2-define-light-theme((
  color: (
    primary: $public-menu-primary,
    accent: $public-menu-accent--deep-purple,
    warn: $public-menu-warn,
  ),
  typography: $public-menu-typography-config,
  density: 0
));

$public-menu-light-theme--indigo: mat.m2-define-light-theme((
  color: (
    primary: $public-menu-primary,
    accent: $public-menu-accent--indigo,
    warn: $public-menu-warn,
  ),
  typography: $public-menu-typography-config,
  density: 0
));

$public-menu-light-theme--blue: mat.m2-define-light-theme((
  color: (
    primary: $public-menu-primary,
    accent: $public-menu-accent--blue,
    warn: $public-menu-warn,
  ),
  typography: $public-menu-typography-config,
  density: 0
));

$public-menu-light-theme--light-blue: mat.m2-define-light-theme((
  color: (
    primary: $public-menu-primary,
    accent: $public-menu-accent--light-blue,
    warn: $public-menu-warn,
  ),
  typography: $public-menu-typography-config,
  density: 0
));

$public-menu-light-theme--cyan: mat.m2-define-light-theme((
  color: (
    primary: $public-menu-primary,
    accent: $public-menu-accent--cyan,
    warn: $public-menu-warn,
  ),
  typography: $public-menu-typography-config,
  density: 0
));

$public-menu-light-theme--teal: mat.m2-define-light-theme((
  color: (
    primary: $public-menu-primary,
    accent: $public-menu-accent--teal,
    warn: $public-menu-warn,
  ),
  typography: $public-menu-typography-config,
  density: 0
));

$public-menu-light-theme--green: mat.m2-define-light-theme((
  color: (
    primary: $public-menu-primary,
    accent: $public-menu-accent--green,
    warn: $public-menu-warn,
  ),
  typography: $public-menu-typography-config,
  density: 0
));

$public-menu-light-theme--light-green: mat.m2-define-light-theme((
  color: (
    primary: $public-menu-primary,
    accent: $public-menu-accent--light-green,
    warn: $public-menu-warn,
  ),
  typography: $public-menu-typography-config,
  density: 0
));

$public-menu-light-theme--lime: mat.m2-define-light-theme((
  color: (
    primary: $public-menu-primary,
    accent: $public-menu-accent--lime,
    warn: $public-menu-warn,
  )
));

$public-menu-light-theme--yellow: mat.m2-define-light-theme((
  color: (
    primary: $public-menu-primary,
    accent: $public-menu-accent--yellow,
    warn: $public-menu-warn,
  ),
  typography: $public-menu-typography-config,
  density: 0
));

$public-menu-light-theme--amber: mat.m2-define-light-theme((
  color: (
    primary: $public-menu-primary,
    accent: $public-menu-accent--amber,
    warn: $public-menu-warn,
  ),
  typography: $public-menu-typography-config,
  density: 0
));

$public-menu-light-theme--orange: mat.m2-define-light-theme((
  color: (
    primary: $public-menu-primary,
    accent: $public-menu-accent--orange,
    warn: $public-menu-warn,
  ),
  typography: $public-menu-typography-config,
  density: 0
));

$public-menu-light-theme--deep-orange: mat.m2-define-light-theme((
  color: (
    primary: $public-menu-primary,
    accent: $public-menu-accent--deep-orange,
    warn: $public-menu-warn,
  ),
  typography: $public-menu-typography-config,
  density: 0
));

$public-menu-light-theme--brown: mat.m2-define-light-theme((
  color: (
    primary: $public-menu-primary,
    accent: $public-menu-accent--brown,
    warn: $public-menu-warn,
  ),
  typography: $public-menu-typography-config,
  density: 0
));

$public-menu-light-theme--grey: mat.m2-define-light-theme((
  color: (
    primary: $public-menu-primary,
    accent: $public-menu-accent--grey,
    warn: $public-menu-warn,
  ),
  typography: $public-menu-typography-config,
  density: 0
));

$public-menu-light-theme--blue-grey: mat.m2-define-light-theme((
  color: (
    primary: $public-menu-primary,
    accent: $public-menu-accent--blue-grey,
    warn: $public-menu-warn,
  ),
  typography: $public-menu-typography-config,
  density: 0
));

// Dark themes
$public-menu-dark-theme: mat.m2-define-dark-theme((
  color: (
    primary: $public-menu-primary,
    accent: $public-menu-accent,
    warn: $public-menu-warn,
  ),
  typography: $public-menu-typography-config,
  density: 0
));

$public-menu-dark-theme--red: mat.m2-define-dark-theme((
  color: (
    primary: $public-menu-primary,
    accent: $public-menu-accent--red,
    warn: $public-menu-warn,
  ),
  typography: $public-menu-typography-config,
  density: 0
));

$public-menu-dark-theme--pink: mat.m2-define-dark-theme((
  color: (
    primary: $public-menu-primary,
    accent: $public-menu-accent--pink,
    warn: $public-menu-warn,
  ),
  typography: $public-menu-typography-config,
  density: 0
));

$public-menu-dark-theme--purple: mat.m2-define-dark-theme((
  color: (
    primary: $public-menu-primary,
    accent: $public-menu-accent--purple,
    warn: $public-menu-warn,
  ),
  typography: $public-menu-typography-config,
  density: 0
));

$public-menu-dark-theme--deep-purple: mat.m2-define-dark-theme((
  color: (
    primary: $public-menu-primary,
    accent: $public-menu-accent--deep-purple,
    warn: $public-menu-warn,
  ),
  typography: $public-menu-typography-config,
  density: 0
));

$public-menu-dark-theme--indigo: mat.m2-define-dark-theme((
  color: (
    primary: $public-menu-primary,
    accent: $public-menu-accent--indigo,
    warn: $public-menu-warn,
  ),
  typography: $public-menu-typography-config,
  density: 0
));

$public-menu-dark-theme--blue: mat.m2-define-dark-theme((
  color: (
    primary: $public-menu-primary,
    accent: $public-menu-accent--blue,
    warn: $public-menu-warn,
  ),
  typography: $public-menu-typography-config,
  density: 0
));

$public-menu-dark-theme--light-blue: mat.m2-define-dark-theme((
  color: (
    primary: $public-menu-primary,
    accent: $public-menu-accent--light-blue,
    warn: $public-menu-warn,
  ),
  typography: $public-menu-typography-config,
  density: 0
));

$public-menu-dark-theme--cyan: mat.m2-define-dark-theme((
  color: (
    primary: $public-menu-primary,
    accent: $public-menu-accent--cyan,
    warn: $public-menu-warn,
  ),
  typography: $public-menu-typography-config,
  density: 0
));

$public-menu-dark-theme--teal: mat.m2-define-dark-theme((
  color: (
    primary: $public-menu-primary,
    accent: $public-menu-accent--teal,
    warn: $public-menu-warn,
  ),
  typography: $public-menu-typography-config,
  density: 0
));

$public-menu-dark-theme--green: mat.m2-define-dark-theme((
  color: (
    primary: $public-menu-primary,
    accent: $public-menu-accent--green,
    warn: $public-menu-warn,
  ),
  typography: $public-menu-typography-config,
  density: 0
));

$public-menu-dark-theme--light-green: mat.m2-define-dark-theme((
  color: (
    primary: $public-menu-primary,
    accent: $public-menu-accent--light-green,
    warn: $public-menu-warn,
  ),
  typography: $public-menu-typography-config,
  density: 0
));

$public-menu-dark-theme--lime: mat.m2-define-dark-theme((
  color: (
    primary: $public-menu-primary,
    accent: $public-menu-accent--lime,
    warn: $public-menu-warn,
  ),
  typography: $public-menu-typography-config,
  density: 0
));

$public-menu-dark-theme--yellow: mat.m2-define-dark-theme((
  color: (
    primary: $public-menu-primary,
    accent: $public-menu-accent--yellow,
    warn: $public-menu-warn,
  ),
  typography: $public-menu-typography-config,
  density: 0
));

$public-menu-dark-theme--amber: mat.m2-define-dark-theme((
  color: (
    primary: $public-menu-primary,
    accent: $public-menu-accent--amber,
    warn: $public-menu-warn,
  ),
  typography: $public-menu-typography-config,
  density: 0
));

$public-menu-dark-theme--orange: mat.m2-define-dark-theme((
  color: (
    primary: $public-menu-primary,
    accent: $public-menu-accent--orange,
    warn: $public-menu-warn,
  ),
  typography: $public-menu-typography-config,
  density: 0
));

$public-menu-dark-theme--deep-orange: mat.m2-define-dark-theme((
  color: (
    primary: $public-menu-primary,
    accent: $public-menu-accent--deep-orange,
    warn: $public-menu-warn,
  ),
  typography: $public-menu-typography-config,
  density: 0
));

$public-menu-dark-theme--brown: mat.m2-define-dark-theme((
  color: (
    primary: $public-menu-primary,
    accent: $public-menu-accent--brown,
    warn: $public-menu-warn,
  ),
  typography: $public-menu-typography-config,
  density: 0
));

$public-menu-dark-theme--grey: mat.m2-define-dark-theme((
  color: (
    primary: $public-menu-primary,
    accent: $public-menu-accent--grey,
    warn: $public-menu-warn,
  ),
  typography: $public-menu-typography-config,
  density: 0
));

$public-menu-dark-theme--blue-grey: mat.m2-define-dark-theme((
  color: (
    primary: $public-menu-primary,
    accent: $public-menu-accent--blue-grey,
    warn: $public-menu-warn,
  ),
  typography: $public-menu-typography-config,
  density: 0
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.

@include mat.core-theme($public-menu-light-theme);
@include mat.all-component-themes($public-menu-light-theme);

.theme-light {
  // Colours not included for the basic light theme because the all components themes above does that.
  @include themes.public-menu-theme($public-menu-light-theme);
  &--red {
    @include mat.all-component-colors($public-menu-light-theme--red);
    @include themes.public-menu-theme($public-menu-light-theme--red);
  }
  &--pink {
    @include mat.all-component-colors($public-menu-light-theme--pink);
    @include themes.public-menu-theme($public-menu-light-theme--pink);
  }
  &--purple {
    @include mat.all-component-colors($public-menu-light-theme--purple);
    @include themes.public-menu-theme($public-menu-light-theme--purple);
  }
  &--deep-purple {
    @include mat.all-component-colors($public-menu-light-theme--deep-purple);
    @include themes.public-menu-theme($public-menu-light-theme--deep-purple);
  }
  &--indigo {
    @include mat.all-component-colors($public-menu-light-theme--indigo);
    @include themes.public-menu-theme($public-menu-light-theme--indigo);
  }
  &--blue {
    @include mat.all-component-colors($public-menu-light-theme--blue);
    @include themes.public-menu-theme($public-menu-light-theme--blue);
  }
  &--light-blue {
    @include mat.all-component-colors($public-menu-light-theme--light-blue);
    @include themes.public-menu-theme($public-menu-light-theme--light-blue);
  }
  &--cyan {
    @include mat.all-component-colors($public-menu-light-theme--cyan);
    @include themes.public-menu-theme($public-menu-light-theme--cyan);
  }
  &--teal {
    @include mat.all-component-colors($public-menu-light-theme--teal);
    @include themes.public-menu-theme($public-menu-light-theme--teal);
  }
  &--green {
    @include mat.all-component-colors($public-menu-light-theme--green);
    @include themes.public-menu-theme($public-menu-light-theme--green);
  }
  &--light-green {
    @include mat.all-component-colors($public-menu-light-theme--light-green);
    @include themes.public-menu-theme($public-menu-light-theme--light-green);
  }
  &--lime {
    @include mat.all-component-colors($public-menu-light-theme--lime);
    @include themes.public-menu-theme($public-menu-light-theme--lime);
  }
  &--yellow {
    @include mat.all-component-colors($public-menu-light-theme--yellow);
    @include themes.public-menu-theme($public-menu-light-theme--yellow);
  }
  &--amber {
    @include mat.all-component-colors($public-menu-light-theme--amber);
    @include themes.public-menu-theme($public-menu-light-theme--amber);
  }
  &--orange {
    @include mat.all-component-colors($public-menu-light-theme--orange);
    @include themes.public-menu-theme($public-menu-light-theme--orange);
  }
  &--deep-orange {
    @include mat.all-component-colors($public-menu-light-theme--deep-orange);
    @include themes.public-menu-theme($public-menu-light-theme--deep-orange);
  }
  &--brown {
    @include mat.all-component-colors($public-menu-light-theme--brown);
    @include themes.public-menu-theme($public-menu-light-theme--brown);
  }
  &--grey {
    @include mat.all-component-colors($public-menu-light-theme--grey);
    @include themes.public-menu-theme($public-menu-light-theme--grey);
  }
  &--blue-grey {
    @include mat.all-component-colors($public-menu-light-theme--blue-grey);
    @include themes.public-menu-theme($public-menu-light-theme--blue-grey);
  }
}

.theme-dark {
  @include mat.all-component-colors($public-menu-dark-theme);
  @include themes.public-menu-theme($public-menu-dark-theme);
  &--red {
    @include mat.all-component-colors($public-menu-dark-theme--red);
    @include themes.public-menu-theme($public-menu-dark-theme--red);
  }
  &--pink {
    @include mat.all-component-colors($public-menu-dark-theme--pink);
    @include themes.public-menu-theme($public-menu-dark-theme--pink);
  }
  &--purple {
    @include mat.all-component-colors($public-menu-dark-theme--purple);
    @include themes.public-menu-theme($public-menu-dark-theme--purple);
  }
  &--deep-purple {
    @include mat.all-component-colors($public-menu-dark-theme--deep-purple);
    @include themes.public-menu-theme($public-menu-dark-theme--deep-purple);
  }
  &--indigo {
    @include mat.all-component-colors($public-menu-dark-theme--indigo);
    @include themes.public-menu-theme($public-menu-dark-theme--indigo);
  }
  &--blue {
    @include mat.all-component-colors($public-menu-dark-theme--blue);
    @include themes.public-menu-theme($public-menu-dark-theme--blue);
  }
  &--light-blue {
    @include mat.all-component-colors($public-menu-dark-theme--light-blue);
    @include themes.public-menu-theme($public-menu-dark-theme--light-blue);
  }
  &--cyan {
    @include mat.all-component-colors($public-menu-dark-theme--cyan);
    @include themes.public-menu-theme($public-menu-dark-theme--cyan);
  }
  &--teal {
    @include mat.all-component-colors($public-menu-dark-theme--teal);
    @include themes.public-menu-theme($public-menu-dark-theme--teal);
  }
  &--green {
    @include mat.all-component-colors($public-menu-dark-theme--green);
    @include themes.public-menu-theme($public-menu-dark-theme--green);
  }
  &--light-green {
    @include mat.all-component-colors($public-menu-dark-theme--light-green);
    @include themes.public-menu-theme($public-menu-dark-theme--light-green);
  }
  &--lime {
    @include mat.all-component-colors($public-menu-dark-theme--lime);
    @include themes.public-menu-theme($public-menu-dark-theme--lime);
  }
  &--yellow {
    @include mat.all-component-colors($public-menu-dark-theme--yellow);
    @include themes.public-menu-theme($public-menu-dark-theme--yellow);
  }
  &--amber {
    @include mat.all-component-colors($public-menu-dark-theme--amber);
    @include themes.public-menu-theme($public-menu-dark-theme--amber);
  }
  &--orange {
    @include mat.all-component-colors($public-menu-dark-theme--orange);
    @include themes.public-menu-theme($public-menu-dark-theme--orange);
  }
  &--deep-orange {
    @include mat.all-component-colors($public-menu-dark-theme--deep-orange);
    @include themes.public-menu-theme($public-menu-dark-theme--deep-orange);
  }
  &--brown {
    @include mat.all-component-colors($public-menu-dark-theme--brown);
    @include themes.public-menu-theme($public-menu-dark-theme--brown);
  }
  &--grey {
    @include mat.all-component-colors($public-menu-dark-theme--grey);
    @include themes.public-menu-theme($public-menu-dark-theme--grey);
  }
  &--blue-grey {
    @include mat.all-component-colors($public-menu-dark-theme--blue-grey);
    @include themes.public-menu-theme($public-menu-dark-theme--blue-grey);
  }
}

@include mat.button-typography($button-theme);

/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }

body, body.theme-light, body.theme-dark {
  font: mat.get-theme-typography($public-menu-light-theme, body-1) !important;
  letter-spacing: mat.get-theme-typography($public-menu-light-theme, body-1, letter-spacing) !important;
  line-height: mat.get-theme-typography($public-menu-light-theme, body-1, line-height) !important;
  margin: 0;
}

h1 {
  font: mat.get-theme-typography($public-menu-light-theme, headline-1);
  letter-spacing: mat.get-theme-typography($public-menu-light-theme, headline-1, letter-spacing);
  margin: 0 0 16px;
}

h2 {
  font: mat.get-theme-typography($public-menu-light-theme, headline-2);
  letter-spacing: mat.get-theme-typography($public-menu-light-theme, headline-2, letter-spacing);
  margin: 0 0 16px;
}

p {
  margin: 0;
}

@media print {
  body {
    font-family: Calibri, "Open Sans", Verdana, Arial, Helvetica, sans-serif !important;
    font-size: 11pt !important;
    color: black !important;
    background-color: white !important;
  }
}
