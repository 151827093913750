@use 'sass:map';
@use '@angular/material' as mat;

@mixin public-menu-theme($theme)
{
  .public-menu-container {
    background: mat.get-theme-color($theme, background, card);
    color: mat.get-theme-color($theme, foreground, text);
  }

  .public-menu-container-border {
    @extend .public-menu-container;
    border: 1px solid mat.get-theme-color($theme, primary, lighter);
  }

  .public-menu-container-error {
    background: mat.get-theme-color($theme, warn, lighter);
    color: mat.get-theme-color($theme, warn, darker);
    border: 3px solid mat.get-theme-color($theme, warn, text);
  }

  .public-menu-container-warning {
    background: mat.get-theme-color($theme, accent, lighter);
    color: mat.get-theme-color($theme, accent, darker);
    border: 3px solid mat.get-theme-color($theme, accent, text);
  }

  .public-menu-container-accent {
    background: mat.get-theme-color($theme, accent, lighter);
    color: mat.get-theme-color($theme, primary, text);
  }

  .public-menu-container-highlight {
    background: mat.get-theme-color($theme, accent);
    color: mat.get-theme-color($theme, primary, text);
  }

  .public-menu-button-selected {
    border-bottom: 2px solid mat.get-theme-color($theme, accent) !important;
    border-radius: 0 !important;
  }
}
